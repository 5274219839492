import { useEffect, useState } from "react";
import confetti from "canvas-confetti";
import * as anchor from "@project-serum/anchor";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { GatewayProvider } from '@civic/solana-gateway-react';
import Countdown from "react-countdown";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { toDate, AlertState, getAtaForMint } from './utils';
import { MintButton } from './MintButton';
import { MultiMintButton } from './MultiMintButton';
import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  mintMultipleToken,
  CANDY_MACHINE_PROGRAM,
} from "./candy-machine";
import { Card, MainContainer, Navbar, Wallet, ConnectButton, MintContainer, DesContainer, MintButtonContainer, SolExplorerLink, Logo, Menu, Collaborations, Guide, Mentions, Friends, Story, Step, StepGuideButtonOne, StepGuideButtonTwo, StepGuideButtonThree, Footer, FooterMenu } from "./Styles";
import herobackground from "./assets/background2.jpg";
import step1 from "./assets/step1.jpg";
import step2 from "./assets/step2.jpg";
import step3 from "./assets/step3.jpg";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

const cluster = process.env.REACT_APP_SOLANA_NETWORK!.toString();
const decimals = process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS ? +process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS!.toString() : 9;
const splTokenName = process.env.REACT_APP_SPL_TOKEN_TO_MINT_NAME ? process.env.REACT_APP_SPL_TOKEN_TO_MINT_NAME.toString() : "TOKEN";

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  txTimeout: number;
  rpcHost: string;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
  const [isActive, setIsActive] = useState(false); // true when countdown completes or whitelisted
  const [solanaExplorerLink, setSolanaExplorerLink] = useState<string>("");
  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [payWithSplToken, setPayWithSplToken] = useState(false);
  const [price, setPrice] = useState(0);
  const [priceLabel, setPriceLabel] = useState<string>("SOL");
  const [whitelistPrice, setWhitelistPrice] = useState(0);
  const [whitelistEnabled, setWhitelistEnabled] = useState(false);
  const [isBurnToken, setIsBurnToken] = useState(false);
  const [whitelistTokenBalance, setWhitelistTokenBalance] = useState(0);
  const [isEnded, setIsEnded] = useState(false);
  const [endDate, setEndDate] = useState<Date>();
  const [isPresale, setIsPresale] = useState(false);
  const [isWLOnly, setIsWLOnly] = useState(false);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const rpcUrl = props.rpcHost;
  const solFeesEstimation = 0.012; // approx of account creation fees

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const cndy = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setCandyMachine(cndy);
      setItemsAvailable(cndy.state.itemsAvailable);
      setItemsRemaining(cndy.state.itemsRemaining);
      setItemsRedeemed(cndy.state.itemsRedeemed);

      var divider = 1;
      if (decimals) {
        divider = +('1' + new Array(decimals).join('0').slice() + '0');
      }

      // detect if using spl-token to mint
      if (cndy.state.tokenMint) {
        setPayWithSplToken(true);
        // Customize your SPL-TOKEN Label HERE
        // TODO: get spl-token metadata name
        setPriceLabel(splTokenName);
        setPrice(cndy.state.price.toNumber() / divider);
        setWhitelistPrice(cndy.state.price.toNumber() / divider);
      } else {
        setPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
        setWhitelistPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
      }


      // fetch whitelist token balance
      if (cndy.state.whitelistMintSettings) {
        setWhitelistEnabled(true);
        setIsBurnToken(cndy.state.whitelistMintSettings.mode.burnEveryTime);
        setIsPresale(cndy.state.whitelistMintSettings.presale);
        setIsWLOnly(!isPresale && cndy.state.whitelistMintSettings.discountPrice === null);

        if (cndy.state.whitelistMintSettings.discountPrice !== null && cndy.state.whitelistMintSettings.discountPrice !== cndy.state.price) {
          if (cndy.state.tokenMint) {
            setWhitelistPrice(cndy.state.whitelistMintSettings.discountPrice?.toNumber() / divider);
          } else {
            setWhitelistPrice(cndy.state.whitelistMintSettings.discountPrice?.toNumber() / LAMPORTS_PER_SOL);
          }
        }

        let balance = 0;
        try {
          const tokenBalance =
            await props.connection.getTokenAccountBalance(
              (
                await getAtaForMint(
                  cndy.state.whitelistMintSettings.mint,
                  wallet.publicKey,
                )
              )[0],
            );

          balance = tokenBalance?.value?.uiAmount || 0;
        } catch (e) {
          console.error(e);
          balance = 0;
        }
        setWhitelistTokenBalance(balance);
        setIsActive(isPresale && !isEnded && balance > 0);
      } else {
        setWhitelistEnabled(false);
      }

      // end the mint when date is reached
      if (cndy?.state.endSettings?.endSettingType.date) {
        setEndDate(toDate(cndy.state.endSettings.number));
        if (
          cndy.state.endSettings.number.toNumber() <
          new Date().getTime() / 1000
        ) {
          setIsEnded(true);
          setIsActive(false);
        }
      }
      // end the mint when amount is reached
      if (cndy?.state.endSettings?.endSettingType.amount) {
        let limit = Math.min(
          cndy.state.endSettings.number.toNumber(),
          cndy.state.itemsAvailable,
        );
        setItemsAvailable(limit);
        if (cndy.state.itemsRedeemed < limit) {
          setItemsRemaining(limit - cndy.state.itemsRedeemed);
        } else {
          setItemsRemaining(0);
          cndy.state.isSoldOut = true;
          setIsEnded(true);
        }
      } else {
        setItemsRemaining(cndy.state.itemsRemaining);
      }

      if (cndy.state.isSoldOut) {
        setIsActive(false);
      }
    })();
  };

  const renderGoLiveDateCounter = ({ days, hours, minutes, seconds }: any) => {
    return (
      <div><Card elevation={0} ><h1>{days}</h1>Days</Card><Card elevation={0}><h1>{hours}</h1>
        Hours</Card><Card elevation={0}><h1>{minutes}</h1>Mins</Card><Card elevation={0}>
          <h1>{seconds}</h1>Secs</Card></div>
    );
  };

  const renderEndDateCounter = ({ days, hours, minutes }: any) => {
    let label = "";
    if (days > 0) {
      label += days + " days "
    }
    if (hours > 0) {
      label += hours + " hours "
    }
    label += (minutes + 1) + " minutes left to MINT."
    return (
      <div><h3>{label}</h3></div>
    );
  };

  function displaySuccess(mintPublicKey: any, qty: number = 1): void {
    let remaining = itemsRemaining - qty;
    setItemsRemaining(remaining);
    setIsSoldOut(remaining === 0);
    if (isBurnToken && whitelistTokenBalance && whitelistTokenBalance > 0) {
      let balance = whitelistTokenBalance - qty;
      setWhitelistTokenBalance(balance);
      setIsActive(isPresale && !isEnded && balance > 0);
    }
    setItemsRedeemed(itemsRedeemed + qty);
    if (!payWithSplToken && balance && balance > 0) {
      setBalance(balance - ((whitelistEnabled ? whitelistPrice : price) * qty) - solFeesEstimation);
    }
    setSolanaExplorerLink(cluster === "devnet" || cluster === "testnet"
      ? ("https://solscan.io/token/" + mintPublicKey + "?cluster=" + cluster)
      : ("https://solscan.io/token/" + mintPublicKey));
    throwConfetti();
  };

  function throwConfetti(): void {
    confetti({
      particleCount: 400,
      spread: 70,
      origin: { y: 0.6 },
    });
  }

  function sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function mintMany(quantityString: number) {
    if (wallet && candyMachine?.program && wallet.publicKey) {
      const quantity = Number(quantityString);
      const futureBalance = (balance || 0) - ((whitelistEnabled && (whitelistTokenBalance > 0) ? whitelistPrice : price) * quantity);
      const signedTransactions: any = await mintMultipleToken(
        candyMachine,
        wallet.publicKey,
        quantity
      );

      const promiseArray = [];

      for (
        let index = 0;
        index < signedTransactions.length;
        index++
      ) {
        const tx = signedTransactions[index];
        promiseArray.push(
          awaitTransactionSignatureConfirmation(
            tx,
            props.txTimeout,
            props.connection,
            "singleGossip",
            true
          )
        );
      }

      const allTransactionsResult = await Promise.all(promiseArray);
      let totalSuccess = 0;
      let totalFailure = 0;

      for (
        let index = 0;
        index < allTransactionsResult.length;
        index++
      ) {
        const transactionStatus = allTransactionsResult[index];
        if (!transactionStatus?.err) {
          totalSuccess += 1;
        } else {
          totalFailure += 1;
        }
      }

      let retry = 0;
      if (allTransactionsResult.length > 0) {
        let newBalance =
          (await props.connection.getBalance(wallet.publicKey)) /
          LAMPORTS_PER_SOL;


        while (newBalance > futureBalance && retry < 20) {
          await sleep(2000);
          newBalance =
            (await props.connection.getBalance(wallet.publicKey)) /
            LAMPORTS_PER_SOL;
          retry++;
          console.log("Estimated balance (" + futureBalance + ") not correct yet, wait a little bit and re-check. Current balance : " + newBalance + ", Retry " + retry);
        }
      }

      if (totalSuccess && retry < 20) {
        setAlertState({
          open: true,
          message: `Congratulations! Your ${quantity} mints succeeded!`,
          severity: 'success',
        });

        // update front-end amounts
        displaySuccess(wallet.publicKey, quantity);
      }

      if (totalFailure || retry === 20) {
        setAlertState({
          open: true,
          message: `Some mints failed! (possibly ${totalFailure}) Wait a few minutes and check your wallet.`,
          severity: 'error',
        });
      }

      if (totalFailure === 0 && totalSuccess === 0) {
        setAlertState({
          open: true,
          message: `Mints manually cancelled.`,
          severity: 'error',
        });
      }
    }
  }

  async function mintOne() {
    if (wallet && candyMachine?.program && wallet.publicKey) {
      const mint = anchor.web3.Keypair.generate();
      const mintTxId = (
        await mintOneToken(candyMachine, wallet.publicKey, mint)
      )[0];

      let status: any = { err: true };
      if (mintTxId) {
        status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          'singleGossip',
          true,
        );
      }

      if (!status?.err) {
        setAlertState({
          open: true,
          message: 'Congratulations! Mint succeeded!',
          severity: 'success',
        });

        // update front-end amounts
        displaySuccess(mint.publicKey);
      } else {
        setAlertState({
          open: true,
          message: 'Mint failed! Please try again!',
          severity: 'error',
        });
      }
    }
  }

  const startMint = async (quantityString: number) => {
    try {
      setIsMinting(true);
      if (quantityString === 1) {
        await mintOne();
      } else {
        await mintMany(quantityString);
      }
    } catch (error: any) {
      let message = error.msg || 'Minting failed! Please try again!';
      if (!error.msg) {
        if (!error.message) {
          message = 'Transaction Timeout! Please try again.';
        } else if (error.message.indexOf('0x138')) {
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      setIsMinting(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
    isEnded,
    isPresale
  ]);

  return (
    <main>
      <MainContainer>
        <Navbar>
          <Logo><a href="http://mint.cryptopanties.xyz/" target="_blank" rel="noopener noreferrer"><img alt=""
            src="logo_rr.png" /></a></Logo>
          <Menu>
            <li><a href="http://www.instagram.com/ravereviewclothes" target="_blank" rel="noopener noreferrer">
              <img alt="instagram logo" src="logo_ig.png" /></a>
            </li>
            <li><a href="https://discord.gg/nZcJCjPZwP" target="_blank" rel="noopener noreferrer">
              <img alt="discord logo" src="logo_discord.png" /></a></li>
            <li><a href="https://twitter.com/cryptopantiesrr" target="_blank" rel="noopener noreferrer">
              <img alt="twitter logo" src="logo_twitter.png" /></a></li>
          </Menu>
          <Wallet>
            {wallet ?
              <ConnectButton /> :
              <ConnectButton>CONNECT WALLET</ConnectButton>}
          </Wallet>
        </Navbar>
        <MintContainer style={{ backgroundImage: `url(${herobackground})` }}>
          <DesContainer>
            <h2>CRYPTOPANTIES BY RAVE REVIEW</h2>
            <h3>NEXT MINT THIS FALL</h3>

            <h3>view collection here</h3>
            <div style={{margin: "20px 0px"}}>
              <a href="https://opensea.io/collection/cryptopanties-by-rave-review" target="_blank" rel="noopener noreferrer" style={{ height: "50px", margin: "20px" }}>
                <img style={{ height: "50px" }} alt="Opensea link" src="logo_os.png" /></a>
              <a href="https://magiceden.io/marketplace/F2z7ERCToxAQaDjoRgHu86wakmmB62FFPKoPYLc4GBJz" target="_blank" rel="noopener noreferrer" style={{ height: "50px", margin: "20px"  }}>
                <img style={{ height: "50px" }} alt="Opensea link" src="logo_me.png" /></a>
            </div>

            {wallet && isActive && whitelistEnabled && (whitelistTokenBalance > 0) && !isBurnToken &&
              <h3>You are whitelisted and allowed to mint.</h3>}
            {/* {wallet && isActive && endDate && Date.now() < endDate.getTime() &&
              <Countdown
                date={toDate(candyMachine?.state?.endSettings?.number)}
                onMount={({ completed }) => completed && setIsEnded(true)}
                onComplete={() => {
                  setIsEnded(true);
                }}
                renderer={renderEndDateCounter}
              />} */}
            {wallet && isActive &&
              <h3>{itemsRedeemed} / {itemsAvailable} MINTED</h3>}
            <MintButtonContainer>
              {/* {!isActive && !isEnded && candyMachine?.state.goLiveDate && (!isWLOnly || whitelistTokenBalance > 0) ? (
                <Countdown
                  date={toDate(candyMachine?.state.goLiveDate)}
                  onMount={({ completed }) => completed && setIsActive(!isEnded)}
                  onComplete={() => {
                    setIsActive(!isEnded);
                  }}
                  renderer={renderGoLiveDateCounter}
                />) : (
                !wallet ? (
                  <>
                    <ConnectButton>CONNECT WALLET</ConnectButton>
                    <CrossmintPayButton
                      collectionTitle="Cryptopanties by Rave Review"
                      collectionDescription="Rave Reviews collection of up-cycled underwear."
                      collectionPhoto="logo.png"
                      clientId="50cf1c90-d3e8-4a76-84c7-994db42b7b8d"
                      className="crossmint-btn"
                    />
                  </>
                ) : (!isWLOnly || whitelistTokenBalance > 0) ?
                  candyMachine?.state.gatekeeper &&
                    wallet.publicKey &&
                    wallet.signTransaction ? (
                    <GatewayProvider
                      wallet={{
                        publicKey:
                          wallet.publicKey ||
                          new PublicKey(CANDY_MACHINE_PROGRAM),
                        //@ts-ignore
                        signTransaction: wallet.signTransaction,
                      }}
                      // // Replace with following when added
                      // gatekeeperNetwork={candyMachine.state.gatekeeper_network}
                      gatekeeperNetwork={
                        candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                      } // This is the ignite (captcha) network
                      /// Don't need this for mainnet
                      clusterUrl={rpcUrl}
                      options={{ autoShowModal: false }}
                    >
                      <MintButton
                        candyMachine={candyMachine}
                        isMinting={isMinting}
                        isActive={isActive}
                        isEnded={isEnded}
                        isSoldOut={isSoldOut}
                        onMint={startMint}
                      />
                    </GatewayProvider>
                  ) : (
                    
                    // <MintButton
                    //     candyMachine={candyMachine}
                    //     isMinting={isMinting}
                    //     isActive={isActive}
                    //     isEnded={isEnded}
                    //     isSoldOut={isSoldOut}
                    //     onMint={startMint}
                    // />
                    <MultiMintButton
                      candyMachine={candyMachine}
                      isMinting={isMinting}
                      isActive={isActive}
                      isEnded={isEnded}
                      isSoldOut={isSoldOut}
                      onMint={startMint}
                      price={whitelistEnabled && (whitelistTokenBalance > 0) ? whitelistPrice : price}
                    />
                  ) :
                  <h1>Mint is private.</h1>
              )} */}
            </MintButtonContainer>
            {/* {wallet && isActive &&
              <p> {isActive && whitelistEnabled && (whitelistTokenBalance > 0) ? ("PRE-SALE PRICE: " + whitelistPrice + " " + priceLabel) : ("PRICE: " + price + " " + priceLabel)}</p>} */}
            {wallet && isActive && whitelistEnabled && (whitelistTokenBalance > 0) &&
              <p>YOU HAVE {whitelistTokenBalance} PRE MINT {whitelistTokenBalance > 1 ? "TOKENS" : "TOKEN"}</p>}
            {/* {true && */}
            {wallet && isActive && solanaExplorerLink &&
              <>
                <h3>Congratiulations</h3>
                <SolExplorerLink href={solanaExplorerLink} target="_blank">View transaction on Solscan</SolExplorerLink>
              </>
            }
            {!solanaExplorerLink &&
              <>
                <h5>Please use a compatible browser, e.g. Phantom, if you're on a mobile device</h5>
                <h5> <a href="https://phantom.app/download" target="_blank"
                  rel="noopener noreferrer">Download Phantom</a></h5>
                <Menu>
                  <li><a href="http://www.instagram.com/ravereviewclothes" target="_blank"
                    rel="noopener noreferrer"><img alt="instagram logo" src="logo_ig.png" /></a></li>
                  <li><a href="https://discord.gg/nZcJCjPZwP" target="_blank"
                    rel="noopener noreferrer"><img alt="discord logo" src="logo_discord.png" /></a></li>
                  <li><a href="https://twitter.com/cryptopantiesrr" target="_blank"
                    rel="noopener noreferrer"><img alt="twitter logo" src="logo_twitter.png" /></a></li>
                </Menu>
                {/* <p>
                  Some of our community members are experiencing issues with minting on Solana at the moment.
                  <br />
                  If transaction fails, please check your wallet in 2 min before trying again.
                </p> */}
              </>
            }
          </DesContainer>
        </MintContainer>
      </MainContainer>
      <Collaborations style={{ backgroundColor: '#ffffff' }}>
        <h5>Mentioned in</h5>
        <Mentions>
          <a target="_blank" rel="noreferrer" href="https://www.voguescandinavia.com/articles/rave-review-makes-a-move-to-metaverse-with-their-cryptopanties">
            <img alt="vouge logo" src="wm_vouge.png" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://hypebae.com/2022/2/rave-review-stockholm-brand-cryptopanties-nft-collection-diversity-metaverse">
            <img alt="hypebae logo" src="wm_hypebae.png" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.yahoo.com/entertainment/cryptopanties-help-guide-women-male-221233780.html">
            <img alt="yahoo logo" src="wm_yahoo.png" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://screenshot-media.com/technology/social-media/cryptopanties-nfts/">
            <img alt="screenshot logo" src="wm_screenshot.png" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.numeroberlin.de/2022/02/on-our-radar/">
            <img alt="numero logo" src="wm_numero.png" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.nssgclub.com/it/fashion/28920/accessories-avantgarde-2022">
            <img alt="gclub logo" src="wm_gclub.png" />
          </a>
        </Mentions>
        <Story>
          <img alt="Rave Review" src="rr-1.png" />
          <p>
            Rave Review is a female-led, high-end upcycling womenswear brand, offering upcycled fashion of an eclectic, unapologetic and strongly recognisable aesthetic. Working solely with pre-owned, overstock and recycled fabrics, it is Rave Review’s ambition to become the most wanted upcycling fashion house in the world.
            <br />
            <br />
            The core of the brand resides in combining creative design and sustainable production, breaking conventions of sustainable fashion aesthetics and empowering our wearers to express themselves with a clear conscience.
            <br />
            <br />
            <a href="https://www.cryptopanties.xyz/" target="_blank"
              rel="noopener noreferrer">visit cryptopanties.xyz</a>
            <br />
            <br />
            <a href="https://www.rave-review.com/" target="_blank"
              rel="noopener noreferrer">visit rave-review.com</a>
            <br />
          </p>
        </Story>
        <h5>Our friends</h5>
        <Friends>
          <a target="_blank" rel="noreferrer" href="https://www.voguescandinavia.com/articles/rave-review-makes-a-move-to-metaverse-with-their-cryptopanties">
            <img alt="gucci logo" src="gucci.png" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/tovestyrke/?hl=en">
            <img alt="tove logo" src="tove_s.png" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://womentribe.art/">
            <img alt="women-tribe logo" src="women-tribe.png" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/arvidabystrom/?hl=en">
            <img alt="arvida logo" src="arvida_b.png" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://reddao.xyz/">
            <img alt="red logo" src="red.png" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://monkedao.io/">
            <img alt="monke-dao logo" src="monke-dao.png" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://werz.at/">
            <img alt="werz logo" src="werz.png" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://zenacademy.io/">
            <img alt="zen logo" src="zen.png" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://boysclub.vip/">
            <img alt="boysclub logo" src="boysclub.png" />
          </a>
        </Friends>
      </Collaborations>
      <Guide>
        <h5>Guides</h5>
        <h2>How to get started</h2>
        <div className="grid">
          <Step style={{
            backgroundImage: `url(${step1})`
          }}>
            <div>
              <h4>STEP 1</h4>
              <a href='https://www.cryptopanties.xyz/guide-1-intro' rel="noopener noreferrer" target="_blank">
                <StepGuideButtonOne className="guide-btn">CREATE WALLET</StepGuideButtonOne>
              </a>
            </div>
          </Step>
          <Step style={{
            backgroundImage: `url(${step2})`
          }}>
            <div>
              <h4>STEP 2</h4>
              <a href='https://www.cryptopanties.xyz/guide-2-intro' rel="noopener noreferrer" target="_blank">
                <StepGuideButtonTwo className="guide-btn">GET SOLANA</StepGuideButtonTwo>
              </a>
            </div>
          </Step>
          <Step style={{
            backgroundImage: `url(${step3})`
          }}>
            <div>
              <h4>STEP 3</h4>
              <a href='https://www.cryptopanties.xyz/guide-3-intro' rel="noopener noreferrer" target="_blank">
                <StepGuideButtonThree className="guide-btn">BUY THE NFT</StepGuideButtonThree>
              </a>
            </div>
          </Step>
        </div>
      </Guide>
      <Footer>
        <p>CRYPTOPANTIES BY</p>
        <img alt="Rave review Logo" src="logo_rr.png" />
        <FooterMenu>
          <a href="http://www.instagram.com/ravereviewclothes" target="_blank"
            rel="noopener noreferrer"><img alt="instagram logo" src="logo_ig.png" /></a>
          <a href="https://discord.gg/nZcJCjPZwP" target="_blank"
            rel="noopener noreferrer"><img alt="discord logo" src="logo_discord.png" /></a>
          <a href="https://twitter.com/cryptopantiesrr" target="_blank"
            rel="noopener noreferrer"><img alt="twitter logo" src="logo_twitter.png" /></a>
        </FooterMenu>
      </Footer>
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

export default Home;
