import styled from "styled-components";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Paper } from "@material-ui/core";

const size = {
  mobileS: '320px',
  mobileM: '400px',
  mobileL: '530px',
  tablet: '788px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`
};

export const Menu = styled.ul`
  list-style: none;
  display: inline-flex;
  justify-content: space-between;
  padding: 0px;
  li {
    margin: 0 12px;
    a {
      color: var(--main-text-color);
      list-style-image: none;
      list-style-possition: outside;
      list-style-type: none;
      outline: none;
      text-decoration: none;
      text-size-adjust: 100%;
      touch-action: manipulation;
      transition: color 0.3s;
      padding-bottom: 15px;
      img {
        max-height: 26px;
      }
    }
    a:hover, a:active {
    }
  }
`;
export const Socials = styled.ul`
  list-style: none;
  display: inline-flex;
  justify-content: center;
  padding: 0px;
  li {
    margin: 0 12px;
    a {
      color: var(--main-text-color);
      list-style-image: none;
      list-style-possition: outside;
      list-style-type: none;
      outline: none;
      text-decoration: none;
      text-size-adjust: 100%;
      touch-action: manipulation;
      transition: color 0.3s;
      padding-bottom: 15px;
      img {
        max-height: 26px;
      }
    }
    a:hover, a:active {
    }
  }
`;
export const Logo = styled.div`
  flex: 0 0 auto;
  align-items: center;
  display: flex;
  z-index: 20;
  img {
    height: 12px;
  }
`;
export const Wallet = styled.ul`
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
`;
export const Navbar = styled.div`
  position: absolute;
  top: 0px;
  padding: 18px 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  ${Wallet} {
    display: inline-flex !important;
  }
  @media ${device.tablet} { 
    ${Menu} {
      display: none !important;
    }
    ${Wallet} {
      display: none !important;
    }
  }
`;
export const ConnectButton = styled(WalletMultiButton)`
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  padding: 32.2609px 0px;
  margin 8px;
  position: relative;
  width: 255px;
  height: 60px;
  font-size: 15px;
  border-radius: 76px;
  background: radial-gradient(44.96% 391.37% at 49.64% 50%, #B5B5B5 2.67%, #5E22FF 100%);
  box-shadow: 0px 0px 29.9565px rgba(94, 34, 255, 0.5), inset 0px 6.91304px 25.3478px #5E22FF, inset 0px -6.91304px 9.21739px #C1AAFF;
  font-family: 'Remake';
  font-weight: 400;
  i {
    display: none;
  }
  :hover {
      cursor: pointer !important;
  }
`;
export const Card = styled(Paper)`
  display: inline-block;
  background-color: var(--countdown-background-color) !important;
  margin: 5px;
  min-width: 40px;
  padding: 24px;
  h1{
    margin:0px;
  }
`;
export const MintButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;
export const SolExplorerLink = styled.a`
color: white;
font-weight: bold;
list-style-image: none;
list-style-position: outside;
list-style-type: none;
outline: none;
margin-top: 24px;
text-decoration: none;
text-size-adjust: 100%;
:hover {
}
`;
export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  z-index: 20;
`;
export const MintContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
height: 100vh;
padding: 140px 20px 20px 20px;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
`;
export const DesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 20px;
  ${Menu}, h5{
    display: none !important;
}
h5 {
    display: none !important;
}
@media ${device.tablet} {
  gap: 10px;
  h1 {
    font-size: 2.84em;
  }
  h3 {
    font-size: 1.2em;
  }
  ${Menu}, h5 {
    display: flex !important;
    justify-content: center;
    gap: 40px;  
  }
}
@media ${device.mobileL} { 
  h1 {
    font-size: 1.8em;
  }
}
`;
export const Collaborations = styled.section`
display: flex;
flex-direction: column;
align-items: center;
padding: 60px 20px 60px 20px;
background-color: #ffffff;
color: #7d7c7c;
`;
export const Mentions = styled.div`
max-width: 800px;
text-align: left;
padding: 20px 0 20px;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
row-gap: 30px;
align-items: center;
justify-items: center;
h5 {
  margin-bottom: 0px;
}
@media ${device.mobileL} { 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
`;
export const Story = styled.div`
max-width: 1000px;
padding: 40px 0px 40px;
font-size: 12px;
display: flex;
align-items: center;   
color: black;
gap: 100px;
a {
  // text-decoration: none;
  color: #000;
}
img {
  max-width: 250px;
}
p {
  max-width: 300px;
  text-align: left;
}
@media ${device.tablet} { 
  gap: 30px;
}
@media ${device.mobileL} { 
  flex-direction: column;
}
`;
export const Friends = styled.div`
width: auto;
max-width: 800px;
display: flex;
align-items: center;
justify-items: center;
flex-wrap: wrap;
justify-content: center;
gap: 20px;
padding: 20px 0;
a {
  display: flex; 
  img {
    max-width: 100px;
  }
}
`;
export const Guide = styled.section`
display: flex;
flex-direction: column;
align-items: center;
padding: 60px 20px 60px 20px;
background-color: #fff;
color: #000;
h5 {
  color: #7d7c7c;
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}
.grid > div {
  padding: 1rem;
  aspect-ratio: 1;
  background-repeat: no-repeat;
  background-size: cover;
}

.grid > div > div{
display: flex;
flex-direction: column;
}
`;
export const Step = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
color: #fff;
background-repeat: no-repeat;
background-size: cover;
div {
  align-items: center;
  h2 {
    margin-bottom: 0px;
  }
  h4 {
    margin-top: 20px;
  }
  a {
    text-decoration: none;
    color: #fff;
  }
  h5 {
    color: #fff;
  }
}
`;
export const StepButton = styled.button`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 32.2609px 0px;
position: relative;
width: 255px;
height: 60px;
border-radius: 76px;
font-size: 15px;
font-family: 'Remake';
font-weight: 400;
color: var(--main-title-text-color);
border: none;
:hover {
  cursor: pointer !important;
}
`;
export const StepGuideButtonOne = styled(StepButton)`
background: radial-gradient(44.96% 391.37% at 49.64% 50%, #EF9E3F 2.67%, #B12995 100%);
box-shadow: 0px 0px 29.9565px rgba(255, 223, 86, 0.2), inset 0px 6.91304px 25.3478px #E8178A, inset 0px -6.91304px 9.21739px #EFA541;
  `;
export const StepGuideButtonTwo = styled(StepButton)`
background: radial-gradient(44.96% 391.37% at 49.64% 50%, #B5B5B5 2.67%, #FFF172 100%);
box-shadow: 0px 0px 29.9565px rgba(255, 223, 87, 0.5), inset 0px 6.91304px 25.3478px #FFDF34, inset 0px -6.91304px 9.21739px rgba(255, 250, 215, 0.81);
  `;
export const StepGuideButtonThree = styled(StepButton)`
background: radial-gradient(44.96% 391.37% at 49.64% 50%, #B5B5B5 2.67%, #5E22FF 100%);
box-shadow: 0px 0px 29.9565px rgba(94, 34, 255, 0.5), inset 0px 6.91304px 25.3478px #5E22FF, inset 0px -6.91304px 9.21739px #C1AAFF;
  `;
export const StepLink = styled.a`
height: 160px;
width: 350px;
display: flex;
flex-direction: column;
justify-content: center;
background-position: center;
background-repeat: no-repeat;
background-size: contain;
text-decoration: none;
color: #fff;
font-size: 15px;
`;
export const Footer = styled.section`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 20px 20px 60px 20px;
background-color: #000;
color: #fff;

p {
  margin: 3px 0px;
}
img {
  height: 9px;
}
div {
  height: 26px;
}
`;
export const FooterMenu = styled.div`
padding-top: 22px;
a {
  padding-right: 17px;
    img {
      height: 22px;
    }
  }
  a:hover, a:active {
  }
`;
